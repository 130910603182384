.login__div{
    background-image: url("../../img/loginBack.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

.login__div__container{
    
    width: 30%;
    position: absolute;
    right: 5%;
    top: 50%;

}

.login__img__log{
    width: 100%;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    margin-bottom: 2%;
}

.login_input_user{
    width: 87%;
    height: 2.25vw;
}

.login_input_password{
    width: 87%;
    height: 2.25vw;
}

.login_log{
    width: 8%;
    margin-top: 0px;
 
}

.log_inputs{
 
    display: flex;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top: 1%;
    justify-content: center;
}

.login__button__div{
    display: flex;
    justify-content: center;
}

.button__login{

    position: relative;
    margin-top: 4%;
    color: white;
    width: 32%;
    background-color: #FF3E73;
}



@media only screen and (max-width: 600px) {
    .login__div {
        background-image: url("../../img/loginBack.png");
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        display: flex;
        align-items: center;
    }

    .login__div__container {
        width: 100%;
        position: absolute;
        right:0;
        top: auto;
    }


    .login_input_user {
        width: 87%;
        height: 8vw;
    }
    .login_input_password{
        width: 87%;
        height: 8vw;
    }
  }