@font-face {
  font-family: "font-page";
  src: url('./Champagne&Limousines.ttf');
  }
body{
  margin: 0;
  padding: 0;
  background: white;
  font-family: "font-page";
  
}
 
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

.react-date-picker__wrapper, .react-datetime-picker__wrapper {
  border-style: hidden !important;
  background-color: white;
  border-radius: 5px;
}

.react-datetime-picker{
  width: 100%;
}

.centrar_verticalmente{
  display: flex;
  align-items: center;
}
.text__rigth{
  text-align: right;
}
@media only screen and (max-width: 600px) {

table {
  display: block;
  overflow-x: auto;
}


.reporte__div__principal {
  background-color: rgba(200, 200, 200, 0.3);
  margin: 10px !important;
  margin-top: 0px;
  border-radius: 25px;
}

}